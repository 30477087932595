<template>
  <div class="my-3">
    <app-card-actions
      v-if="guestList && guestList.length > 0"
      action-collapse
      flat
      class="rounded-xl"
    >
      <template slot="title">
        {{ $t('wrongNumber') }}
        <v-badge
          overlap
          :content="guestList.length"
          offset-x="0"
          offset-y="4"
        ></v-badge>
      </template>

      <v-card-text>
        <wrong-number-table></wrong-number-table>
      </v-card-text>
    </app-card-actions>
  </div>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import WrongNumberTable from './WrongNumberTable.vue'

export default {
  components: { WrongNumberTable, AppCardActions },
  setup() {
    const guestList = ref(null)

    const guestWithWrongNumber = () => {
      store.dispatch('inviteList/fetchGuestsWithWrongNumber').then(res => {
        guestList.value = res.data
      })
    }
    guestWithWrongNumber()

    return {
      guestWithWrongNumber,
      guestList,
    }
  },
}
</script>
