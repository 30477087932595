<template>
  <div v-if="loading">
    <v-dialog
      v-model="loading"
      dark
      persistent
      width="300"
    >
      <v-card
        color="primary"
        width="300"
      >
        <v-card-text class="pt-3">
          <p class="mb-2">
            {{ $t('pleaseWait') }}
          </p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-file-input
      id="fileInput"
      outlined
      style="display: none"
      accept=".xlsx, .xls"
      @change="handleFileUpload"
    >
    </v-file-input>
    <v-btn
      dir="auto"
      :class="!importContactsFromTourGuide? 'mb-2 px-2':'justify-center align-center rounded-lg my-2'"
      :block="!importContactsFromTourGuide ? false : true "
      :text="!importContactsFromTourGuide ? true : false "
      :outlined="!importContactsFromTourGuide ? false : true "
      :x-large="!importContactsFromTourGuide ? false : true "
      @click="triggerFileInput"
    >
      <img
        :src="require(`@/assets/images/logos/excel.png`)"
        alt="excel logo"
        class="excel-logo"
      >
      <span class="mx-2">{{ $t('UploadExcelFile') }}</span>
    </v-btn>
    <v-dialog
      v-model="dialogVisible"
      width="400px"
    >
      <v-card class="rounded-xl">
        <v-form ref="form">
          <v-card-title class="justify-center">
            <span class="primary--text">
              {{ $t('ImportYourInviteeFromExcel') }}
            </span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold justify-center text-center">
            *{{ $t('FileMustHaveTitles') }}
          </v-card-subtitle>
          <v-card-subtitle>
            {{ $t('ChooseForEachColumnFromExcel') }}
          </v-card-subtitle>
          <v-card-text
            v-for="(column) in tableColumnsNeeded"
            :key="column"
            class="py-1"
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col
                cols="5"
                class="text-start pr-2"
              >
                {{ $t(column) }}:
              </v-col>
              <v-col
                cols="7"
              >
                <v-select
                  v-model="columnMappings[column]"
                  :rules="[validators.required]"
                  :items="headersFromExcel"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center pt-10">
            <v-btn
              color="primary"
              rounded
              @click="saveHeaders"
            >
              {{ $t('Ok') }}
            </v-btn>
            <v-btn
              outlined
              rounded
              color="primary"
              @click="dialogVisible = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <ErrorsFromExcel
      v-if="inviteesWithErrors.length > 0"
      :invitees-with-errors="inviteesWithErrors"
      :type="'excel'"
      @moveToGuestList="moveToGuestList"
    ></ErrorsFromExcel>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
// eslint-disable-next-line object-curly-newline
import usePopupWithHash from '@/composables/usePopupWithHash'
import {
  PhoneNumberLength,
  invalidPhoneNumber,
  maxLenInput,
  minLenInput,
  required,
  validateInviteeNumber,
} from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import * as XLSX from 'xlsx'
import ErrorsFromExcel from './ErrorsFromExcel.vue'

export default {
  components: { ErrorsFromExcel },
  props: ['importContactsFromTourGuide'],
  setup(props, { emit }) {
    const loading = ref(false)
    const { isPopupOpen: dialogVisible } = usePopupWithHash('import-from-excel')

    const tableColumnsNeeded = ref(['fullName', 'phoneNumber', 'countOfInvites'])
    const headersFromExcel = ref([])
    const columnMappings = ref({})
    const processedData = ref([])
    const rowData = ref([])
    const form = ref(null)
    const inviteesWithErrors = ref([])

    const toast = useToast()
    const showToast = error => toast.error(i18n.t(error))
    const successToast = (val, num) => toast.success(`${i18n.t(val)} ${num} ${i18n.t('Invitees')}`)

    // Reset file
    const triggerFileInput = () => {
      // Reset the file input by clearing its value
      const fileInputElement = document.getElementById('fileInput')
      if (fileInputElement) {
        fileInputElement.value = '' // Clear the input
      }

      // Then trigger the click to open the file picker
      fileInputElement.click()
    }
    const uniqueInvitee = ref([])

    const validateExistInvitee = (arr, data) => {
      if (data.length === 0) return

      // Create a Set of phone numbers from arr for efficient lookup
      const arrExistsPhoneNumbers = arr.map(item => item.phoneNumber)

      // Filter data to include only elements whose phoneNumber is not present in arrPhoneNumbers
      uniqueInvitee.value = data.filter(item => !arrExistsPhoneNumbers.includes(item.phoneNumber))
    }
    const showErrorInvitees = errorsValue => {
      if (errorsValue.length === 0) return

      inviteesWithErrors.value = errorsValue
    }

    const moveToGuestList = () => {
      inviteesWithErrors.value = []
      emit('moveToGuestList')
    }

    const submitInvitees = (data, errorsData) => {
      if (data.length === 0) return

      validateExistInvitee(store.getters['inviteList/getUserListTable'], data)

      if (uniqueInvitee.value.length === 0) {
        // If no have error to show, show notification
        if (errorsData.length === 0) {
          showToast('AllInviteeAlreadyExist')
        }
        showErrorInvitees(errorsData)

        return
      }

      if (errorsData.length > 0) {
        showErrorInvitees(errorsData)
      }

      store
        .dispatch('inviteList/submitContacts', {
          contacts: uniqueInvitee.value,
          lang: store.state.eventData.defaultLang,
          groups: [],
        })
        .then(res => {
          successToast('SuccessfullyAdded', res.data.countSuccess)

          // If the number of guests fails to be added, show a message to upgrade the package
          if (res.data.countFailed > 0) {
            store.commit('inviteList/setShowLimitReachedPopup', true)
          }
        })
        .catch(() => {
          showToast('errorManageEvents')
          if (props.importContactsFromTourGuide) {
            moveToGuestList()
          }
        })
        .finally(() => {
          store.dispatch('inviteList/fetchGuests')
        })
    }

    const findDuplicatePhoneNumbers = data => {
      const phoneNumbers = data.map(item => item.phoneNumber)
      const duplicates = phoneNumbers.filter((phoneNumber, index, self) => self.indexOf(phoneNumber) !== index)

      return duplicates
    }

    // Validate importing invitees
    const validateInvitees = (value, existingPhoneNumbers, duplicatePhoneNumbers) => {
      const errors = []

      const tmpValue = ref(value.phoneNumber ? value.phoneNumber.replace(/\s+/g, '') : undefined)

      // Process tmpValue if it's defined and starts with '+972'
      if (tmpValue.value !== undefined && tmpValue.value.startsWith('+972')) {
        tmpValue.value = `0${tmpValue.value.slice(4)}`
      }

      if (
        // eslint-disable-next-line operator-linebreak
        tmpValue.value === undefined ||
        // eslint-disable-next-line operator-linebreak
        invalidPhoneNumber(tmpValue.value) !== true ||
        PhoneNumberLength(tmpValue.value) !== true
      ) {
        errors.push('phoneNumber')
      } else if (existingPhoneNumbers.includes(tmpValue.value) || duplicatePhoneNumbers.includes(tmpValue.value)) {
        errors.push('phoneNumberExist')
      }
      if (value.countOfInvites === undefined || validateInviteeNumber(value.countOfInvites) !== true) {
        errors.push('countOfInvites')
      }
      if (value.fullName === undefined || minLenInput(value.fullName) !== true) {
        errors.push('fullName')
      }

      return errors
    }

    const handleArrays = () => {
      const duplicatePhoneNumbers = findDuplicatePhoneNumbers(processedData.value)
      const existingPhoneNumbers = store.getters['inviteList/getUserListTable'].map(item => item.phoneNumber)

      const result = processedData.value.reduce(
        (cur, val) => {
          const errors = validateInvitees(val, existingPhoneNumbers, duplicatePhoneNumbers)

          // Cut the invitee name if it over 30 char
          if (maxLenInput(val.fullName) !== true) {
            // eslint-disable-next-line no-param-reassign
            val.fullName = val.fullName.slice(0, 30)
          }

          // If no have errors, add invitee to validated array
          if (errors.length === 0) {
            if (val.phoneNumber[0] === '0') {
              // eslint-disable-next-line no-param-reassign
              val.phoneNumber = `+972${val.phoneNumber.slice(1, 10)}`
            }
            cur.validatedInvitees.push(val)
          } else {
            // If have errors, add invitee and errors array to errors object
            cur.withErrors.push({
              ...val,
              errors,
            })
          }

          return cur
        },
        { validatedInvitees: [], withErrors: [] },
      )

      // add validate data to guest table, and show a popup with errors if have
      submitInvitees(result.validatedInvitees, result.withErrors)
    }

    const processDataBasedOnMappings = () => {
      processedData.value = rowData.value
        .map(row => {
          const transformedRow = {}

          // Loop through the columnMappings to create a new object
          // where the keys are from columnMappings and values from the row data
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, excelHeader] of Object.entries(columnMappings.value)) {
            // Find the index of the header in headersFromExcel
            const headerIndex = headersFromExcel.value.findIndex(header => header === excelHeader)
            if (headerIndex !== -1) {
              // Use the index to get the correct value from the row
              let value = row[headerIndex]

              if (key === 'phoneNumber' && value) {
                // Remove hyphens from the phone number
                // Ensure value is a string before replacing
                value = String(value).trim().replace(/-/g, '') // Trim spaces and remove all hyphens
              }

              if (value) {
                transformedRow[key] = value
              }
            }
          }

          return transformedRow
        })
        .filter(
          row =>
            // eslint-disable-next-line lines-around-comment
            // Keep the row unless all specified keys are undefined
            // eslint-disable-next-line implicit-arrow-linebreak
            !(row.fullName === undefined && row.phoneNumber === undefined),
        )

      if (processedData.value.length === 0) {
        showToast(i18n.t('FileIsEmpty'))

        return
      }

      // Validate full name with regex; must include at least two Unicode letter characters and may contain numbers, spaces, apostrophes, or hyphens
      const validNameRegex = /^(?=.*\p{L}.*\p{L})[\p{L}\p{N} '-]+$/u

      // Check if all fullName fields have a valid format
      const isValidFormat = processedData.value.some(
        contact =>
          // eslint-disable-next-line implicit-arrow-linebreak, operator-linebreak
          typeof contact.fullName === 'string' &&
          // eslint-disable-next-line operator-linebreak
          contact.fullName.trim().length > 0 &&
          validNameRegex.test(contact.fullName),
      )

      // Check the phoneNumber format for each processed row
      const isValidFormatPhone = processedData.value.some(
        contact => typeof contact.phoneNumber === 'string' && contact.phoneNumber.trim().length > 0,
      )

      // Check the countOfInvites format for each processed row
      const isValidFormatCountOfInvitee = processedData.value.some(
        contact => typeof contact.countOfInvites === 'number' && contact.countOfInvites > 0,
      )

      if (!isValidFormat) {
        showToast('InvalidFullNameFormatColumn')

        return // Stop the process if invalid format detected
      }

      if (!isValidFormatPhone) {
        showToast('isValidFormatPhoneColumn')

        return // Stop the process if invalid format detected
      }
      if (!isValidFormatCountOfInvitee) {
        showToast('isValidFormatCountOfInviteeColumn')

        return // Stop the process if invalid format detected
      }

      // Validate all excel rows
      handleArrays()
    }

    // Save user fitting between columns excel file and required fields
    const saveHeaders = () => {
      if (form.value.validate()) {
        dialogVisible.value = false
        processDataBasedOnMappings() // Process the data with the mappings
      }
    }

    // Read excel file
    const handleFileUpload = file => {
      if (!file) return

      if (!/\.(xlsx|xls)$/i.test(file.name)) {
        showToast(i18n.t('InvalidFileType'))

        return
      }

      loading.value = true
      const reader = new FileReader()

      reader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary' })

        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Utility function to check if a cell is part of any merged cell
        const isMergedCell = (rowIndex, colIndex) => {
          if (!worksheet['!merges']) return false

          return worksheet['!merges'].some(
            merge => rowIndex >= merge.s.r && rowIndex <= merge.e.r && colIndex >= merge.s.c && colIndex <= merge.e.c,
          )
        }

        // Convert sheet to JSON, read all rows as potential data
        const allRows = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        if (allRows.length === 0) {
          showToast(i18n.t('FileIsEmpty'))
          loading.value = false

          return
        }

        // Find the first row with more than 3 non-merged cells
        const headerRowIndex = allRows.findIndex((row, rowIndex) => {
          let nonMergedCellCount = 0
          row.forEach((cell, colIndex) => {
            if (cell !== '' && !isMergedCell(rowIndex, colIndex)) {
              nonMergedCellCount += 1
            }
          })

          return nonMergedCellCount >= 3
        })

        if (headerRowIndex === -1) {
          showToast(i18n.t('InvalidFile'))
          loading.value = false

          return
        }

        headersFromExcel.value = allRows[headerRowIndex]

        // Remove the headers and rows above headers, then filter out empty rows
        rowData.value = allRows.slice(headerRowIndex + 1).filter(row => row.some(cell => cell != null && cell !== ''))

        if (rowData.value.length === 0) {
          showToast(i18n.t('FileIsEmpty'))
          loading.value = false

          return
        }

        if (headersFromExcel.value) {
          dialogVisible.value = true
        }

        loading.value = false
      }

      reader.onerror = () => {
        loading.value = false
        showToast(i18n.t('errorManageEvents'))
      }

      reader.readAsBinaryString(file)
    }

    // If dialog is closed, reset the form
    watch(dialogVisible, () => {
      if (form.value) {
        form.value.reset()
      }
    })

    return {
      loading,
      handleFileUpload,
      dialogVisible,
      tableColumnsNeeded,
      headersFromExcel,
      columnMappings,
      saveHeaders,
      processedData,
      processDataBasedOnMappings,
      triggerFileInput,
      form,
      inviteesWithErrors,
      uniqueInvitee,
      moveToGuestList,

      validators: {
        required,
      },
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.excel-logo {
  width: 20px;
  height: 20px;
}
.v-btn::before {
  background-color: transparent;
}
</style>
