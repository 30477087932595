<template>
  <div>
    <v-data-table
      :items="$store.getters['inviteList/getWrongNumberListTable']"
      :headers="tableColumns"
      :no-data-text="$t('NoHaveInvitees')"
      hide-default-footer
      disable-sort
    >
      <!-- table index -->
      <template #[`item.tableIndex`]="{index, item}">
        <div class="d-flex">
          <div class="">
            {{ index + 1 }}
          </div>
          <!-- view invitation icon -->
          <div class="mx-2">
            <v-icon
              v-if="item.guestViews.length > 0"
              color="blue"
            >
              {{ icons.mdiEye }}
            </v-icon>
          </div>
        </div>
      </template>

      <!-- Full name -->
      <template #[`header.fullName`]>
        <span class="header-center"> {{ $t('fullName') }} </span>
      </template>

      <template #[`item.fullName`]="{item}">
        <div
          class="d-flex align-center"
        >
          <v-avatar
            class="v-avatar-light-bg"
            :color="getStatusOptionStatsField(item.status, 'color')"
            :class="`${getStatusOptionStatsField(item.status, 'color')}--text`"
            size="32"
          >
            <span
              class="font-weight-medium"
            >{{ avatarText(item.fullName) }}</span>
          </v-avatar>
          <div class="d-flex flex-column ms-2">
            <span
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.fullName }}
            </span>
          </div>
        </div>
      </template>

      <!-- phone number -->
      <template #[`header.phoneNumber`]>
        <span class="header-center"> {{ $t('phoneNumber') }} </span>
      </template>

      <template #[`item.phoneNumber`]="{item}">
        <div class="text-capitalize text--primary">
          {{ item.phoneNumber.slice(0,4) === '+972' ? 0+item.phoneNumber.slice(4,15) : item.phoneNumber }}
        </div>
      </template>

      <!-- progress -->
      <template #[`header.progress`]>
        <span class="header-center"> {{ $t('Progress') }} </span>
      </template>

      <template #[`item.progress`]="{item}">
        <v-row class="flex-nowrap justify-center">
          <v-badge
            overlap
            :color="item.guestActions.smsCount > 0 ? 'primary white--text': 'grey black--text'"
            class="me-3"
            left
            offset-x="10"
            offset-y="10"
            :content="`${item.guestActions.smsCount}`"
          >
            <v-avatar
              size="32"
              color="grey"
              class="primary--text"
            >
              <v-icon
                size="18"
                :class="item.guestActions.smsCount > 0 ? 'primary--text' : 'black--text'"
              >
                {{ icons.mdiMessageProcessingOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>

          <v-badge
            :color="item.guestActions.callCount > 0 ? 'primary white--text': 'grey black--text'"
            left
            offset-x="10"
            offset-y="10"
            :content="`${item.guestActions.callCount}`"
          >
            <v-avatar
              color="grey"
              size="30"
            >
              <v-icon
                size="18"
                :class="item.guestActions.callCount > 0 ? 'primary--text' : 'black--text'"
              >
                {{ icons.mdiPhoneOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>
        </v-row>
      </template>
      <template #[`item.edit`]="{item}">
        <v-btn
          color="primary"
          class="rounded-xl"
          outlined
          @click="editItem(item)"
        >
          {{ $t('Edit') }}
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-if="guestData"
      v-model="dialogVisible"
      persistent
      width="400"
    >
      <v-card
        max-height="350"
        class="rounded-xl"
      >
        <v-card-title>
          {{ $t('EditPhoneNumber') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-col
          cols="12"
          md="12"
          class="mx-auto"
        >
          <v-card-text>
            <v-card-subtitle
              v-if="showNoteNumberNotChanged"
              class="red--text pa-0"
            >
              *{{ $t('PhoneNumberNotChanged') }}
            </v-card-subtitle>
            <v-form ref="editWrongNumber">
              <v-text-field
                v-model="phoneNum"
                :label="$t('phoneNumber')"
                :rules="[validators.required, validators.integerValidator, validators.maxPhoneNumber, validators.PhoneNumberLength ]"
                outlined
                dense
                rounded
                maxlength="20"
                class="pt-5"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-btn
              rounded
              color="primary"
              class="me-2"
              @click="savePhoneNumber"
            >
              {{ $t('Ok') }}
            </v-btn>
            <v-btn
              color="primary"
              rounded
              outlined
              @click="closeDialog"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n/index'
import { mdiEye, mdiMessageProcessingOutline, mdiPhoneOutline } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import useGuestStatuses from '@/composables/useGuestStatuses'
// eslint-disable-next-line object-curly-newline
import { integerValidator, maxPhoneNumber, required, PhoneNumberLength } from '@core/utils/validation'
import { useToast } from 'vue-toastification/composition'

export default {
  props: ['guestList'],
  setup() {
    const { statusOptions } = useGuestStatuses()
    const editWrongNumber = ref()
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    const tableColumns = computed(() => [
      {
        text: '#',
        value: 'tableIndex',
        sortable: false,
        width: '20',
      },
      {
        text: i18n.t('fullName'),
        value: 'fullName',
        align: 'center',
        width: '150',
      },
      {
        text: i18n.t('phoneNumber'),
        value: 'phoneNumber',
        sortable: false,
        align: 'center',
        width: 'auto',
      },
      {
        text: i18n.t('Progress'),
        value: 'progress',
        sortable: false,
        align: 'center',
        width: 'auto',
      },
      {
        text: '',
        value: 'edit',
        sortable: false,
        align: 'center',
        width: 'auto',
      },
    ])

    const getStatusOptionStatsField = (key, field) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      statusOptions.filter(el => el.value === key).map(el => el[field])[0]

    const dialogVisible = ref(false)
    const guestData = ref(null)
    const phoneNum = ref()

    // Edit guest phone number
    const editItem = item => {
      guestData.value = item
      phoneNum.value = `0${guestData.value.phoneNumber.slice(4)}`
      dialogVisible.value = true
    }

    // Show warning note - user not changed guest phone number
    const showNoteNumberNotChanged = ref(false)
    const checkIfPhoneNumberChanged = newNumber => {
      const slicedPhoneNumber = `0${guestData.value.phoneNumber.slice(4)}`
      if (newNumber !== slicedPhoneNumber) {
        return true
      }
      showNoteNumberNotChanged.value = true

      return false
    }

    const savePhoneNumber = () => {
      // check if the number if changed!
      if (editWrongNumber.value.validate() && checkIfPhoneNumberChanged(phoneNum.value)) {
        guestData.value = { ...guestData.value, phoneNumber: `+972${phoneNum.value.slice(1, 10)}`, status: 1 }

        // Remove unnecessary keys
        // eslint-disable-next-line object-curly-newline
        const { createdAt, updatedAt, token, guestViews, guestActions, ...sanitizedGuestData } = {
          ...guestData.value,
        }
        store
          .dispatch('editGuest', {
            id: store.state.eventData.id,
            guestID: guestData.value.id,
            guestData: sanitizedGuestData,
          })
          .then(() => {
            window.location.reload()
          })
          .catch(() => {
            showToast(i18n.t('errorManageEvents'))
            window.location.reload()
          })
          .finally(() => {
            dialogVisible.value = false
          })
      }
    }

    const closeDialog = () => {
      dialogVisible.value = false
      showNoteNumberNotChanged.value = false
    }

    return {
      tableColumns,
      avatarText,
      getStatusOptionStatsField,
      editItem,
      guestData,
      dialogVisible,
      phoneNum,
      savePhoneNumber,
      editWrongNumber,
      showNoteNumberNotChanged,
      closeDialog,

      icons: {
        mdiMessageProcessingOutline,
        mdiPhoneOutline,
        mdiEye,
      },

      validators: {
        required,
        integerValidator,
        PhoneNumberLength,
        maxPhoneNumber,
      },
    }
  },
}
</script>
<style scoped>
.header-center {
  display: inline-table;
  width: 100%;
}
</style>
