<template>
  <div>
    <v-overlay
      v-if="$store.getters['inviteList/getLoading']"
      z-index="10"
      opacity="0.35"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div>
      <!-- Statistic card -->
      <v-row class="mb-2 justify-center">
        <v-col
          v-if="
            ($store.getters['inviteList/getNumberOfInvitees'] > 0 && $vuetify.breakpoint.smAndDown) ||
              $vuetify.breakpoint.mdAndUp
          "
          cols="12"
          md="8"
          lg="8"
          sm="12"
          offset-sm="12"
        >
          <GuestStatusCards />
        </v-col>
        <v-col
          v-if="
            ($store.state.eventData.progressStatus !== 0 && $vuetify.breakpoint.mdAndDown) ||
              $vuetify.breakpoint.mdAndUp
          "
          cols="12"
          md="4"
          lg="4"
          sm="12"
          offset-sm="12"
        >
          <TimingsPreview />
        </v-col>
      </v-row>

      <!-- Guest filters  -->
      <GuestFilters />

      <WrongNumberTab />

      <!-- Guests table -->
      <div ref="guestsTableRef">
        <GuestsTable></GuestsTable>
      </div>
    </div>
  </div>
</template>

<script>
import GuestFilters from '@/components/GuestManagement/GuestFilters.vue'
import GuestsTable from '@/components/GuestManagement/GuestsTable.vue'
import GuestStatusCards from '@/components/GuestManagement/GuestStatusCards.vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { onUnmounted, ref, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import inviteListStore from '@/store/modules/inviteListStore'
// eslint-disable-next-line import/no-cycle
import WrongNumberTab from '@/components/GuestManagement/WrongNumberTab.vue'
import TimingsPreview from '@/components/Timing/TimingsPreview.vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/user-list/useUsersList'
import useEventListeners from '@/composables/useEventListeners'

export default {
  components: {
    GuestStatusCards,
    GuestFilters,
    GuestsTable,
    TimingsPreview,
    WrongNumberTab,
  },
  props: {
    guestId: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const INVITE_STORE_MODULE_NAME = 'inviteList'

    // Register module
    if (!store.hasModule(INVITE_STORE_MODULE_NAME)) store.registerModule(INVITE_STORE_MODULE_NAME, inviteListStore)

    const guestsTableRef = ref(null)
    const { fetchUserTable } = useUsersList()
    const { addListener, removeAllListenersForEvent } = useEventListeners()

    onMounted(() => {
      addListener('SOFT_RELOAD', () => {
        fetchUserTable()
      })
    })

    onUnmounted(() => {
      removeAllListenersForEvent('SOFT_RELOAD')
      if (store.hasModule(INVITE_STORE_MODULE_NAME)) store.unregisterModule(INVITE_STORE_MODULE_NAME)
    })

    fetchUserTable().then(() => {
      if (props.guestId) {
        setTimeout(() => {
          guestsTableRef.value.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
        }, 500)
      }
    })

    return {
      guestsTableRef,
      fetchUserTable,
    }
  },
}
</script>
