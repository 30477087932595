<template>
  <div>
    <v-dialog
      v-model="isLoading"
      dark
      persistent
      width="300"
    >
      <v-card
        color="primary"
        width="300"
      >
        <v-card-text class="pt-3">
          <p class="mb-2">
            {{ $t('pleaseWait') }}
          </p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <GoogleContactsSelection
      :contacts="contacts"
      :import-contacts-from-tour-guide="importContactsFromTourGuide"
      @moveToGuestList="moveToGuestList"
    ></GoogleContactsSelection>
    <v-btn
      v-if="isInWebView"
      :class="!importContactsFromTourGuide ? 'mb-2 px-2':'justify-center align-center rounded-lg my-2'"
      dir="auto"
      :block="!importContactsFromTourGuide ? false : true"
      :text="!importContactsFromTourGuide ? true : false"
      :outlined="!importContactsFromTourGuide ? false : true"
      :x-large="!importContactsFromTourGuide ? false : true"
      @click.stop="handleImportContacts"
    >
      <v-icon
        size="20"
        color="primary"
        :class="importContactsFromTourGuide ? 'mx-2' : ''"
      >
        {{ icons.mdiContacts }}
      </v-icon>
      <span
        :class="!importContactsFromTourGuide ? 'mx-2' : ''"
        style="margin: -2px;"
      >{{ $t('importContacts') }}</span>
    </v-btn>

    <v-btn
      v-else
      :class="!importContactsFromTourGuide ? 'mb-2 px-2':'justify-center align-center rounded-lg my-2'"
      dir="auto"
      :block="!importContactsFromTourGuide ? false : true"
      :text="!importContactsFromTourGuide ? true : false"
      :outlined="!importContactsFromTourGuide ? false : true"
      :x-large="!importContactsFromTourGuide ? false : true"
      @click.stop="handleImportContacts"
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google logo"
        class="google-logo"
      />
      <span
        class="mx-2"
        style="margin: 13px !important"
      >{{ $t('ImportContactsViaGoogle') }}</span>
    </v-btn>
  </div>
</template>

<script>
import useGoogleContacts from '@/composables/useGoogleContacts'
import useWebViewContacts from '@/composables/useWebViewContacts'
import store from '@/store'
import { mdiContacts } from '@mdi/js'
import { onMounted, onUnmounted } from '@vue/composition-api'
import useEventListeners from '@/composables/useEventListeners'
import GoogleContactsSelection from './GoogleContactsSelection.vue'

export default {
  components: {
    GoogleContactsSelection,
  },
  props: ['importContactsFromTourGuide'],
  setup(props, { emit }) {
    const { addListener, removeAllListenersForEvent } = useEventListeners()
    const {
      contacts: googleContacts, isLoading: googleLoading, getGoogleContacts, loadGapi,
    } = useGoogleContacts()
    const {
      contacts: phoneContacts,
      isLoading: phoneLoading,
      requestPhoneContacts,
      handlePhoneContactsResponse,
    } = useWebViewContacts()

    const isInWebView = store.getters['app/getIsInWebView']
    const contacts = isInWebView ? phoneContacts : googleContacts
    const isLoading = isInWebView ? phoneLoading : googleLoading

    const moveToGuestList = () => {
      emit('moveToGuestList')
    }

    // Send the request to the WebView for contacts if in WebView
    const handleImportContacts = () => {
      if (isInWebView) {
        requestPhoneContacts()
      } else {
        getGoogleContacts()
      }
    }

    onMounted(() => {
      // Listen for the response from the WebView
      addListener('PHONE_CONTACTS_RESPONSE', event => {
        const data = event.detail
        handlePhoneContactsResponse(data)
      })
    })

    onUnmounted(() => {
      removeAllListenersForEvent('PHONE_CONTACTS_RESPONSE')
    })

    if (!isInWebView) {
      loadGapi()
    }

    return {
      contacts,
      isLoading,
      handleImportContacts,
      moveToGuestList,
      isInWebView,
      icons: {
        mdiContacts,
      },
    }
  },
}
</script>

<style>
.google-logo {
  width: 18px;
  height: 18px;
}
</style>
