import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import formatPhoneNumber from '@core/utils/phoneNumberFormatter'

export default function useWebViewContacts() {
  const contacts = ref([])
  const isLoading = ref(false)
  const toast = useToast()

  // Function to filter out contacts that already exist in the guest list
  const filterPrimaryContacts = data => {
    const currentGuests = store.getters['inviteList/getUserListTable']
    const addedPhoneNumbers = new Set()

    const unfilteredContacts = data.map(item => {
      if (!item?.fullName || !item?.phoneNumber) {
        return null
      }

      // Format the phone number to a standard format
      const formattedPhoneNumber = formatPhoneNumber(item.phoneNumber)

      return {
        fullName: item.fullName,
        phoneNumber: formattedPhoneNumber,
      }
    })

    return unfilteredContacts.filter(contact => {
      const formattedPhoneNumber = contact?.phoneNumber
      if (
        contact?.fullName
        && formattedPhoneNumber
        && !currentGuests.find(guest => guest.phoneNumber === formattedPhoneNumber)
        && !addedPhoneNumbers.has(formattedPhoneNumber)
      ) {
        addedPhoneNumbers.add(formattedPhoneNumber)

        return true
      }

      return false
    })
  }

  const requestPhoneContacts = () => {
    isLoading.value = true
    const data = { type: 'REQUEST_PHONE_CONTACTS' }
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  }

  const handlePhoneContactsResponse = data => {
    isLoading.value = false
    if (data.contacts) {
      contacts.value = filterPrimaryContacts(data.contacts)
    } else {
      toast.error('Failed to load contacts')
    }
  }

  return {
    contacts,
    isLoading,
    requestPhoneContacts,
    handlePhoneContactsResponse,
  }
}
