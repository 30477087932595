import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'

export default function useGoogleContacts() {
  const contacts = ref([])
  const isLoading = ref(false)
  const toast = useToast()
  const showToast = message => toast.error(i18n.t(message))

  const filterPrimaryContacts = data => {
    const currentGuests = store.getters['inviteList/getUserListTable']
    const addedPhoneNumbers = new Set()

    const unfilteredContacts = Object.values(data).map(item => {
      if (!item?.names || !item?.phoneNumbers) {
        return null
      }
      const primaryName = item.names.find(name => name.metadata.primary)
      const primaryPhone = item.phoneNumbers.find(phone => phone.metadata.primary)

      return {
        fullName: primaryName ? primaryName.displayName : '',
        phoneNumber: primaryPhone ? primaryPhone.canonicalForm : '',
      }
    })

    return unfilteredContacts.filter(contact => {
      if (
        contact?.fullName
        && contact?.phoneNumber
        && !currentGuests.find(guest => guest.phoneNumber === contact.phoneNumber)
        && !addedPhoneNumbers.has(contact.phoneNumber)
      ) {
        addedPhoneNumbers.add(contact.phoneNumber)

        return true
      }

      return false
    })
  }

  const onGapiLoad = async () => {
    gapi.load('client', initializeGapiClient)
  }

  const loadGapi = async () => {
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/platform.js'
    script.onload = () => onGapiLoad()
    document.body.appendChild(script)
  }

  const initializeGapiClient = async () => {
    await gapi.client.init({
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/contacts.readonly',
      plugin_name: 'contacts',
    })
  }

  const getGoogleContacts = async () => {
    try {
      isLoading.value = true
      await gapi.auth2.getAuthInstance().signIn()

      const apiUrl = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,emailAddresses'
      let pageToken = ''
      let allContacts = []

      do {
        const response = await gapi.client.request({
          path: `${apiUrl}&pageToken=${pageToken}`,
        })

        const contacts = response.result.connections
        if (contacts) {
          allContacts = [...allContacts, ...contacts]
        }

        pageToken = response.result.nextPageToken
      } while (pageToken)

      contacts.value = filterPrimaryContacts(allContacts)

      // Track the successful import of contacts from Google
      Vue.prototype.$gtag.event('Import_Contacts', {
        event_category: 'Contact Management',
        event_label: 'Google Contacts',
      })

      isLoading.value = false
    } catch (error) {
      isLoading.value = false
      showToast(i18n.t('errorFetchingGoogleContacts'))
    }
  }

  return {
    contacts,
    isLoading,
    getGoogleContacts,
    loadGapi,
  }
}
