var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.$store.getters['inviteList/getWrongNumberListTable'],"headers":_vm.tableColumns,"no-data-text":_vm.$t('NoHaveInvitees'),"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.tableIndex",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"mx-2"},[(item.guestViews.length > 0)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]):_vm._e()],1)])]}},{key:"header.fullName",fn:function(){return [_c('span',{staticClass:"header-center"},[_vm._v(" "+_vm._s(_vm.$t('fullName'))+" ")])]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg",class:((_vm.getStatusOptionStatsField(item.status, 'color')) + "--text"),attrs:{"color":_vm.getStatusOptionStatsField(item.status, 'color'),"size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])]),_c('div',{staticClass:"d-flex flex-column ms-2"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"},[_vm._v(" "+_vm._s(item.fullName)+" ")])])],1)]}},{key:"header.phoneNumber",fn:function(){return [_c('span',{staticClass:"header-center"},[_vm._v(" "+_vm._s(_vm.$t('phoneNumber'))+" ")])]},proxy:true},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-capitalize text--primary"},[_vm._v(" "+_vm._s(item.phoneNumber.slice(0,4) === '+972' ? 0+item.phoneNumber.slice(4,15) : item.phoneNumber)+" ")])]}},{key:"header.progress",fn:function(){return [_c('span',{staticClass:"header-center"},[_vm._v(" "+_vm._s(_vm.$t('Progress'))+" ")])]},proxy:true},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap justify-center"},[_c('v-badge',{staticClass:"me-3",attrs:{"overlap":"","color":item.guestActions.smsCount > 0 ? 'primary white--text': 'grey black--text',"left":"","offset-x":"10","offset-y":"10","content":("" + (item.guestActions.smsCount))}},[_c('v-avatar',{staticClass:"primary--text",attrs:{"size":"32","color":"grey"}},[_c('v-icon',{class:item.guestActions.smsCount > 0 ? 'primary--text' : 'black--text',attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMessageProcessingOutline)+" ")])],1)],1),_c('v-badge',{attrs:{"color":item.guestActions.callCount > 0 ? 'primary white--text': 'grey black--text',"left":"","offset-x":"10","offset-y":"10","content":("" + (item.guestActions.callCount))}},[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{class:item.guestActions.callCount > 0 ? 'primary--text' : 'black--text',attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPhoneOutline)+" ")])],1)],1)],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-xl",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")])]}}],null,true)}),(_vm.guestData)?_c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"max-height":"350"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('EditPhoneNumber'))+" ")]),_c('v-divider'),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[(_vm.showNoteNumberNotChanged)?_c('v-card-subtitle',{staticClass:"red--text pa-0"},[_vm._v(" *"+_vm._s(_vm.$t('PhoneNumberNotChanged'))+" ")]):_vm._e(),_c('v-form',{ref:"editWrongNumber"},[_c('v-text-field',{staticClass:"pt-5",attrs:{"label":_vm.$t('phoneNumber'),"rules":[_vm.validators.required, _vm.validators.integerValidator, _vm.validators.maxPhoneNumber, _vm.validators.PhoneNumberLength ],"outlined":"","dense":"","rounded":"","maxlength":"20"},model:{value:(_vm.phoneNum),callback:function ($$v) {_vm.phoneNum=$$v},expression:"phoneNum"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"me-2",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.savePhoneNumber}},[_vm._v(" "+_vm._s(_vm.$t('Ok'))+" ")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","outlined":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }