import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { computed, ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const { mealsTypes } = useSpecialMealsTypes()

  const tableColumns = computed(() => [
    {
      text: '#',
      value: 'tableIndex',
      sortable: false,
    },
    {
      text: i18n.t('fullName'),
      value: 'fullName',
      align: 'center',
      width: 'auto',
    },
    {
      text: i18n.t('phoneNumber'),
      value: 'phoneNumber',
      sortable: false,
      align: 'center',
      width: 'auto',
    },
    {
      text: i18n.t('status'),
      value: 'status',
      align: 'center',
      width: 'auto',
    },
    {
      text: i18n.t('NumberGuestAnswer'),
      value: 'NumberGuestAnswer',
      align: 'center',
      width: 'auto',
    },
    {
      text: i18n.t('ClassGroup'),
      value: 'classGroup',
      sortable: false,
      align: 'center',
      width: 'auto',
    },
    {
      text: i18n.t('comments'),
      value: 'comment',
      align: 'center',
      width: '180',
    },

    {
      text: i18n.t('Progress'),
      value: 'progress',
      sortable: false,
      align: 'center',
      width: 'auto',
    },
  ])

  // add to table columns if setting event have transport option
  const displayTrans = () => {
    if (store.state.eventData.transport !== 0) {
      tableColumns.value.splice(6, 0, {
        text: i18n.t('Transport'),
        value: 'trans',
        align: 'center',
      })
    }
  }

  // add to table columns if setting event have special meals option
  const displayMeals = () => {
    if (store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK) {
      tableColumns.value.splice(6, 0, {
        text: i18n.t('specialMeals'),
        value: 'meal',
        align: 'center',
      })
    }
  }

  const loading = ref(false)
  const counter = ref(0)
  const lastGuestsFetched = ref([])
  const userListTable = ref([])

  const fetchUserTable = (filters = {}) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: true } }, { filters })

  watch(
    () => i18n.locale,
    () => {
      displayMeals()
      displayTrans()
    },
  )

  return {
    userListTable,
    counter,
    tableColumns,
    displayTrans,
    displayMeals,
    loading,
    fetchUserTable,
    lastGuestsFetched,
    mealsTypes,
  }
}
