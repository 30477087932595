<template>
  <v-expand-transition>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title @click="triggerCollapse">
        <!-- Enclose the title in a div for the click event, excluding action icons -->
        <div
          style="align-items: center;white-space: nowrap;"
        >
          <slot name="title"></slot>
        </div>

        <v-spacer></v-spacer>
        <div>
          <!-- Slot: Before Actions -->
          <slot name="before-actions"></slot>

          <!-- Action Buttons -->
          <v-btn
            v-show="actionCollapse && !noActions && canCollapse"
            icon
            small
            @click.stop="triggerCollapse"
          >
            <v-icon
              size="20"
              :style="{ transform: isContentCollapsed ? 'rotate(-180deg)' : 'rotate(0deg)' }"
            >
              {{ icons.mdiChevronUp }}
            </v-icon>
          </v-btn>

          <!-- Refresh -->
          <v-btn
            v-show="(!(actionCollapse || actionRemove) || actionRefresh) && !noActions"
            icon
            small
            @click="triggerRefresh"
          >
            <v-icon size="20">
              {{ icons.mdiRefresh }}
            </v-icon>
          </v-btn>

          <!-- Close -->
          <v-btn
            v-show="(!(actionCollapse || actionRefresh) || actionRemove) && !noActions"
            icon
            small
            @click="triggerRemove"
          >
            <v-icon size="20">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
          <!-- /Action Buttons -->

          <!-- Slot: After Actions -->
          <slot name="after-actions"></slot>
        </div>
      </v-card-title>

      <v-expand-transition>
        <div v-show="!isContentCollapsed">
          <slot></slot>
        </div>
      </v-expand-transition>

      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { getVuetify } from '@core/utils'
import { mdiChevronUp, mdiClose, mdiRefresh } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  inheritAttrs: false,
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    actionCollapse: {
      type: Boolean,
      default: false,
    },
    actionRefresh: {
      type: Boolean,
      default: false,
    },
    actionRemove: {
      type: Boolean,
      default: false,
    },
    startRsvp: {
      type: Boolean,
      default: false,
    },
    beforePay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showOverlay = ref(false)
    const isContentCollapsed = ref(props.collapsed)
    const isRemoved = ref(false)
    const $vuetify = getVuetify()

    // Dynamically enable/disable collapse functionality
    const canCollapse = ref(true)

    const showCollapse = () => {
      if (props.beforePay) {
        isContentCollapsed.value = false // Opened collapsed
        canCollapse.value = false // Disable collapsing functionality
      }
    }
    showCollapse()

    // Watch for changes in the viewport size to adjust the collapsed state and collapsibility
    watch(
      () => $vuetify.breakpoint.mdAndUp,
      newVal => {
        if (newVal) {
          // Functionality only for start RSVP date card.
          if (props.startRsvp || props.beforePay) {
            isContentCollapsed.value = false // Opened collapsed when the screen is large
            canCollapse.value = false // Disable collapsing functionality
          }
        } else if (!props.beforePay) {
          // On smaller screens, allow content to be collapsed
          canCollapse.value = true
        }
      },
      { immediate: true },
    )

    // Action Utils
    const hideOveraly = () => {
      showOverlay.value = false
    }

    // Action Triggers
    const triggerCollapse = () => {
      // Only toggle collapsed state if collapsing is enabled
      if (!canCollapse.value) return

      isContentCollapsed.value = !isContentCollapsed.value
      emit('collapsed', isContentCollapsed.value)
    }
    const triggerRefresh = () => {
      showOverlay.value = true
      emit('refresh', hideOveraly)
    }
    const triggerRemove = () => {
      isRemoved.value = true
      emit('removed')
    }

    return {
      showOverlay,
      isContentCollapsed,
      isRemoved,

      // Triggerer
      triggerRefresh,
      triggerCollapse,
      triggerRemove,

      canCollapse,

      // Icons
      icons: {
        mdiChevronUp,
        mdiRefresh,
        mdiClose,
      },
    }
  },
}
</script>

<style>
</style>
