export default function formatPhoneNumber(phoneNumber) {
  const cleanedNumber = phoneNumber.replace(/\D/g, '')

  if (cleanedNumber.startsWith('972')) {
    if (cleanedNumber.length === 12) {
      return `+${cleanedNumber}`
    }

    if (cleanedNumber.length === 9 || cleanedNumber.length === 10) {
      return `+972${cleanedNumber.slice(-9)}`
    }
  }

  if (cleanedNumber.startsWith('0') && cleanedNumber.length === 10) {
    return `+972${cleanedNumber.slice(1)}`
  }

  if (cleanedNumber.length >= 9 && !cleanedNumber.startsWith('+')) {
    return `+${cleanedNumber}`
  }

  return phoneNumber
}
