<template>
  <div>
    <v-dialog
      v-model="isDialogVisible"
      fullscreen
      :overlay="false"
      transition="dialog-bottom-transition"
    >
      <!-- Dialog Content -->
      <v-card>
        <!-- Toolbar -->
        <v-toolbar
          color="primary"
          dark
        >
          <v-btn
            icon
            plain
            @click="closeContactsSelection"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('importContacts') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-show="selectedContacts.length > 0"
            text
            @click="handleArrays"
          >
            {{ $t('submitImportContacts') }}
          </v-btn>
        </v-toolbar>

        <v-form ref="settingsForm">
          <v-list>
            <v-subheader>{{ $t('importContactsSettingsDesc') }}</v-subheader>
            <v-row
              justify="center"
              dense
            >
              <v-col
                cols="6"
                lg="2"
                md="2"
                sm="6"
                xs="6"
              >
                <v-select
                  v-model="selectedLang"
                  :items="$store.state.eventData.languages"
                  :rules="[validators.required]"
                  :item-text="item => $t(item.lang)"
                  item-value="lang"
                  :label="$t('Language')"
                  :menu-props="['offset-y']"
                  hide-details="auto"
                  class="mb-4"
                  rounded
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                v-if="$store.state.groupsData.length > 0"
                cols="6"
                lg="2"
                md="2"
                sm="6"
                xs="6"
              >
                <v-select
                  v-model="selectedGroups"
                  :items="$store.state.eventData.groups"
                  item-text="name"
                  item-value="id"
                  :label="$t('ClassGroup')"
                  :menu-props="['offset-y']"
                  hide-details="auto"
                  class="mb-4"
                  outlined
                  rounded
                  dense
                  clearable
                ></v-select>
                <v-btn
                  :to="{name: 'apps-user-manage-group'}"
                  color="primary"
                  outlined
                  rounded
                  class="mb-6"
                  block
                >
                  {{ $t('NewGroup') }}
                </v-btn>
              </v-col>

              <v-col
                v-if="($store.state.eventData.transport !== 0)"
                cols="5"
                lg="1"
                md="2"
                sm="6"
                xs="6"
                class="text-caption font-weight-regular"
              >
                <v-row
                  class="mt-auto"
                  no-gutters
                >
                  <v-col
                    cols="8"
                  >
                    <label>{{ $t('AskTransportation') }}</label>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-switch
                      v-model="askTransport"
                      hide-details
                      class="pt-0 mt-0 pb-5"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                cols="5"
                lg="1"
                md="2"
                sm="6"
                xs="6"
                class="settings-list text-caption font-weight-regular"
              >
                <v-row
                  class="mt-auto"
                  no-gutters
                >
                  <v-col
                    cols="10"
                  >
                    <label>{{ $t('AskSpecialMeals') }}</label>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <v-switch
                      v-model="askSpecialDish"
                      hide-details
                      class="pt-0 mt-0 pb-5"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-list>
        </v-form>

        <v-divider></v-divider>

        <v-card
          class="mt-3"
          flat
        >
          <v-col
            cols="12"
            md="3"
            sm="6"
            xs="12"
          >
            <v-text-field
              v-model="searchQuery"
              :placeholder="$t('search')"
              outlined
              hide-details
              dense
              class="user-search"
              rounded
            >
            </v-text-field>
          </v-col>

          <v-data-table
            v-model="selectedContacts"
            :headers="tableColumns"
            :items="allContacts"
            show-select
            item-key="phoneNumber"
            :header-props="{ sortByText: $t('SortBy') }"
            hide-default-footer
            :items-per-page="itemsPerPage"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <!-- name -->
            <template #[`header.fullName`]>
              <span> {{ $t('fullName') }} </span>
            </template>

            <template #[`item.fullName`]="{item}">
              <div
                class="d-flex align-center"
              >
                <v-avatar
                  class="v-avatar-light-bg primary--text"
                  color="primary"
                  size="32"
                >
                  <span
                    class="font-weight-medium"
                  >{{ avatarText(item.fullName) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-2">
                  <span
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.fullName }}
                  </span>
                </div>
              </div>
            </template>

            <!-- phone number -->
            <template #[`header.phoneNumber`]>
              <span> {{ $t('phoneNumber') }} </span>
            </template>

            <template #[`item.phoneNumber`]="{item}">
              <div class="text-capitalize text--primary">
                {{ item.phoneNumber.slice(0,4) === '+972' ? 0+item.phoneNumber.slice(4,15) : item.phoneNumber }}
              </div>
            </template>
          </v-data-table>

          <div class="text-center pt-2 pb-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <ErrorsFromExcel
      v-if="inviteesWithErrors.length > 0"
      :invitees-with-errors="inviteesWithErrors"
      :type="'google'"
      @moveToGuestList="moveToGuestList"
    ></ErrorsFromExcel>
  </div>
</template>

<script>
import usePopupWithHash from '@/composables/usePopupWithHash'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import {
  PhoneNumberLength,
  invalidPhoneNumber,
  maxLenInput,
  minLenInput,
  required,
  validateInviteeNumber,
} from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ErrorsFromExcel from './ErrorsFromExcel.vue'

export default {
  components: { ErrorsFromExcel },
  props: {
    contacts: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    importContactsFromTourGuide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { mealsTypes } = useSpecialMealsTypes()

    const { isPopupOpen: isDialogVisible } = usePopupWithHash('contacts-selection')

    const selectedContacts = ref([])
    const searchQuery = ref('')
    const allContacts = ref([])
    const settingsForm = ref(null)
    const toast = useToast()
    const successToast = (val, num) => toast.success(`${i18n.t(val)} ${num} ${i18n.t('Invitees')}`)
    const showToast = message => toast.error(i18n.t(message))

    // Pagination
    const page = ref(1)
    const pageCount = ref(0)
    const itemsPerPage = ref(10)

    // Settings for all imported contacts
    const selectedLang = ref()
    const selectedGroups = ref()

    // if user select for his event ask all guests about transport
    const askTransport = ref(store.state.eventData.transport === 2 ?? false)
    const askSpecialDish = ref(false)

    const tableColumns = ref([
      {
        text: i18n.t('fullName'),
        value: 'fullName',
        align: 'center',
        width: '200',
      },
      {
        text: i18n.t('phoneNumber'),
        value: 'phoneNumber',
        sortable: false,
        align: 'center',
        width: 'auto',
      },
    ])

    watch(
      () => props.contacts,
      selection => {
        if (selection.length > 0) {
          allContacts.value = selection
          isDialogVisible.value = true
        }
      },
    )

    watch([searchQuery], () => {
      const queryLowered = searchQuery.value.toLowerCase()
      allContacts.value = props.contacts.filter(
        user =>
          // eslint-disable-next-line operator-linebreak, implicit-arrow-linebreak
          user.fullName.search(queryLowered) !== -1 ||
          user.phoneNumber.search(queryLowered[0] === '0' ? queryLowered.slice(1) : queryLowered) !== -1,
      )
    })

    const submitContacts = data => {
      selectedContacts.value = []
      if (settingsForm.value.validate() && data.length > 0) {
        store
          .dispatch('inviteList/submitContacts', {
            contacts: data,
            lang: selectedLang.value,
            groups: [selectedGroups.value],
            canAskTransport: askTransport.value,
            canAskSpecialDish: askSpecialDish.value,
          })
          .then(res => {
            successToast('SuccessfullyAdded', res.data.countSuccess)

            // If the number of guests fails to be added, show a message to upgrade the package
            if (res.data.countFailed > 0) {
              store.commit('inviteList/setShowLimitReachedPopup', true)
            }
            isDialogVisible.value = false
          })
          .catch(() => {
            showToast('errorManageEvents')
          })
          .finally(() => {
            store.dispatch('inviteList/fetchGuests')
          })
      }
    }
    const inviteesWithErrors = ref([])

    const showErrorInvitees = errorsValue => {
      if (errorsValue.length === 0) return

      inviteesWithErrors.value = errorsValue
    }

    const moveToGuestList = () => {
      inviteesWithErrors.value = []
      emit('moveToGuestList')
    }

    // Validate importing invitees
    const validateInvitees = value => {
      const errors = []
      const tmpValue = ref(value.phoneNumber)
      if (tmpValue.value.slice(0, 4) === '+972') {
        tmpValue.value = `0${tmpValue.value.slice(4, 15)}`
      }
      if (invalidPhoneNumber(tmpValue.value) !== true || PhoneNumberLength(tmpValue.value) !== true) {
        errors.push('phoneNumber')
      }
      if (minLenInput(value.fullName) !== true) {
        errors.push('fullName')
      }

      return errors
    }
    const handleArrays = () => {
      if (!settingsForm.value.validate()) {
        return
      }

      const result = selectedContacts.value.reduce(
        (cur, val) => {
          const errors = validateInvitees(val)

          // Cut the invitee name if it over 30 char
          if (maxLenInput(val.fullName) !== true) {
            // eslint-disable-next-line no-param-reassign
            val.fullName = val.fullName.slice(0, 30)
          }

          // If no have errors, add invitee to validated array
          if (errors.length === 0) {
            // eslint-disable-next-line no-param-reassign
            if (val.phoneNumber[0] === '0') {
              // eslint-disable-next-line no-param-reassign
              val.phoneNumber = `+972${val.phoneNumber.slice(1, 10)}`
            }
            cur.validatedInvitees.push(val)
          } else {
            // If have errors, add invitee and errors array to errors object
            cur.withErrors.push({
              ...val,
              errors,
            })
          }

          return cur
        },
        { validatedInvitees: [], withErrors: [] },
      )

      // Add only validated invitee
      submitContacts(result.validatedInvitees)

      // Show table with guests errors
      showErrorInvitees(result.withErrors)
    }

    const closeContactsSelection = () => {
      isDialogVisible.value = false
      store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: false } })
    }

    return {
      isDialogVisible,
      allContacts,
      selectedContacts,
      tableColumns,
      avatarText,
      searchQuery,
      page,
      pageCount,
      itemsPerPage,
      selectedLang,
      selectedGroups,
      askTransport,
      askSpecialDish,
      handleArrays,
      settingsForm,
      submitContacts,
      inviteesWithErrors,
      validateInvitees,
      mealsTypes,
      closeContactsSelection,
      moveToGuestList,

      validators: {
        required,
        invalidPhoneNumber,
        validateInviteeNumber,
        minLenInput,
        PhoneNumberLength,
        maxLenInput,
      },
    }
  },
}
</script>

<style lang="scss">
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.25s ease-in-out;
}
</style>
