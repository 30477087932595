<template>
  <v-card
    flat
    class="rounded-xl"
    height="100%"
  >
   <v-card-title :class="{'pt-3': !$vuetify.breakpoint.xsOnly}">
        {{ $t('closestAction') }}
      <v-spacer></v-spacer>
      <v-btn
        text
        class="px-0"
        :to="{name: 'apps-user-manage-timing'}"
        plain
        style="min-width: 0px; font-size: 12px;"
      >
        {{ $t('Edit') }}
      </v-btn>
    </v-card-title>
   <v-card-text>
      <v-row
        align="center"
      >
        <v-col
          v-if="closestAction"
          cols="auto"
          class="d-flex flex-grow-1"
          style="align-items: self-end"
        >
          <v-avatar
            :size="$vuetify.breakpoint.mdAndDown? 40 : 50"
            :class="`v-avatar-light-bg ${getNotificationStatus(closestAction.type, 'color')}--text`"
            style="align-self: center;"
          >
            <v-icon
              size="25"
              class="rounded-0"
              :color="messagesGuestsTypeMapping.filter(el => el.value === closestAction.type)[0].color"
            >
              {{getNotificationStatus(closestAction.type, 'icon') }}
            </v-icon>
          </v-avatar>

          <div
            class="mx-2 d-flex flex-column align-items-center"
            style="width: 100%;"
            v-if="closestAction"
          >
          <div class="mb-0 mb-1 d-flex">
            <div>
              <p class="mb-0 font-weight-medium text-base">
              {{ $t(getNotificationStatus(closestAction.type, 'title')) }} {{ (closestAction.type === 4 || closestAction.type === 1) ? $t('round') + (closestAction.round + 1) : '' }}
            </p>
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-chip
                class="v-chip-light-bg font-weight-semibold text-capitalize"
                :class="$store.state.eventData.progressStatus > 0 ? 'success--text' : ''"
                small
              >
                {{ $store.state.eventData.progressStatus > 0 ? $t('Active') : $t('NotActive') }}
              </v-chip>
            </div>
          </div>
              <div class="d-flex">
                <v-icon
                  size="20"
                >
                  {{ icons.mdiCalendarClock }}
                </v-icon>
                <div class="mx-1">
                  {{ moment(closestAction.startFrom).format('DD/MM/YY') || 'not set' }}
                </div>
                <div>|</div>
                <div class="mx-1">
                  {{ $t('startFrom') }}{{ moment(closestAction.startFrom).format('HH:mm') || 'not set' }}
                </div>
              </div>

          </div>




        </v-col>


      </v-row>
    </v-card-text>
    <v-card-text
      v-if="!closestAction"
      class="text-center font-weight-bold text-lg"
    >
      {{ $t('NoHaveClosestAction') }}
    </v-card-text>
  </v-card>
</template>

<script>
import useGuestMessages from '@/composables/useGuestMessages'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiCalendarClock, mdiCircleSmall, mdiHeart } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'

export default {
  setup() {
    const { messagesGuestsTypeMapping } = useGuestMessages()

    const loaded = ref(false)
    const now = moment(new Date())
    const closestAction = ref(null)

    const toast = useToast()
    const showToast = message => toast.error(i18n.t(message))

    const getNotificationStatus = (key, field) => {
      return messagesGuestsTypeMapping.filter(el => el.value === key).map(el => el[field])[0]
    }

    const searchType = type => {
      if (type === null) return ''

      if (type === 1) {
        return i18n.t('invitationMessage')
      }
      if (type === 2) {
        return i18n.t('DirectionMessage')
      }
      if (type === 3) {
        return i18n.t('ThanksMsg')
      }
      if (type === 4) {
        return i18n.t('call')
      }
    }

    const calculateClosestTiming = timingData => {
      if (timingData && timingData.length > 0) {
        // Filter out past timings
        const futureTimings = timingData.filter(timing => moment(timing.startFrom).isAfter(now))

        // No have future timing
        if (futureTimings.length === 0) {
          return null
        }

        let closestTiming = futureTimings[0]
        let closestDiff = Math.abs(now.diff(moment(futureTimings[0].startFrom)))

        futureTimings.forEach(timing => {
          const timingMoment = moment(timing.startFrom)
          const diff = Math.abs(now.diff(timingMoment))

          if (diff < closestDiff) {
            closestDiff = diff
            closestTiming = timing
          }
        })

        return closestTiming
      }

      return null
    }

    const fetchTiming = (data = {}) => {
      store
        .dispatch('fetchTiming', { id: store.state.eventData.id, data })
        .then(() => {
          closestAction.value = calculateClosestTiming(store.state.timingData)
          loaded.value = true
        })
        .catch(() => {
          showToast('errorManageEvents')
        })
    }

    fetchTiming()

    return {
      moment,
      loaded,
      now,
      calculateClosestTiming,
      closestAction,
      searchType,
      messagesGuestsTypeMapping,
      getNotificationStatus,

      icons: {
        mdiCalendarClock,
        mdiCircleSmall,
        mdiHeart,
      },
    }
  },
}
</script>
